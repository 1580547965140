class SignzyStorageService {

    constructor(operationMode, ttl) {
        /*
            Mode can be
            1. session -> sessionStorage
            2. persistent -> localStorage
            3. cookie -> cookie, and lets expect a ttl too, if not 1 day will be ttl
        */
        this.mode = operationMode || "session";
        this.ttl = ttl || 24 * 60 * 60

    }

    getData(documentName) {
        let data = undefined;
        switch (this.mode) {
            case "session":
                return sessionStorage[documentName];
            case "persistent":
                return localStorage[documentName];
            case "cookie":
                data = document.cookie.split("; ").filter(i => i.split("=")[0] == documentName)[0];
                return data ? data.split("=")[1] : undefined;
        }
    }

    setData(documentName, data) {
        switch (this.mode) {
            case "session":
                sessionStorage[documentName] = data;
                return;
            case "persistent":
                localStorage[documentName] = data;
                return
            case "cookie":
                if(typeof data == "object"){
                    data = JSON.stringify(data);
                }
                document.cookie = `${documentName}=${data}; max-age=${this.ttl}; SameSite=None; Secure`
                return
        }
    }

    flush() {
        let cookies = undefined;
        switch (this.mode) {
            case "session":
                sessionStorage.clear();
                break;
            case "persistent":
                localStorage.clear();
                break;
            case "cookie":
                cookies = document.cookie.split(";");
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    let eqPos = cookie.indexOf("=");
                    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
                break;
        }
    }

}

module.exports = {
    SignzyStorageService
}
