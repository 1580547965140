<template>
  <v-app>
    <loader />
    <snackbar />
    <router-view />
  </v-app>
</template>

<script>
import loader from "@/components/Loader";
import snackbar from "@/components/Snackbar";
export default {
  methods: {
  },
  created () {
  },
  props: {
  },
  components: {
    loader,
    snackbar
  }
};
</script>