import Vue from 'vue'
import Router from 'vue-router'
import {userDataStore} from './UserData.Store'

const Login = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/Login.vue');

const PassUpdate = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/PassUpdate.vue');
const RejectPage = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/RejectPage.vue');
const DropHandler = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/DropHandler.vue');

//All related components for vcip admin
const Dashboard = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Dashboard.vue');
const Teams = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Teams.vue');
const VCIPCalls = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/VcipCalls.vue');
const VCIPVideo = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Video.vue');
const Conference = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Conference/Conference.vue');

//All related components for customer
const ControlPanel = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/ControlPanel.vue');
const CreateAgent = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/CreateAgent.vue');
const CreateManager = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/CreateManager.vue');
const CreateUser = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/CreateUser.vue');
const ListUsers = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/ListUsers.vue');
const MisReports = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/MisReports.vue');
const Settings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/Settings.vue');
const BasicSettings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/BasicSettings.vue');
const AdminGlobalSettings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/GlobalSettings.vue');
const AdvancedSettings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/AdvancedSettings.vue');
const ConferenceSettings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/ConferenceSettings.vue');
const SkillSets = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/SkillSets.vue');
const InstructionSet = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/InstructionSet.vue');
const MandatoryPool = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/InstructionPool/MandatoryPool.vue');
const OptionalPool = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/InstructionPool/OptionalPool.vue');
const UVKYCBasicSettings = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/UVKYCBasicSettings.vue');
const UVKYCInstructionSet = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/UVKYCInstructionSet.vue');
const InstructionPage = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/InstructionPage.vue');
const AdminForm = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Settings/AdminForm.vue');
const Audit = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Audit/Audit.vue');
const CompletedAudit = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Audit/CompletedAudit.vue');
const PendingAudit = () =>
    import( /* webpackChunkName: "customer" */ './../Pages/Admin/Audit/PendingAudit.vue');

//All related components for Super admin
const SuperPanel = () =>
    import( /* webpackChunkName: "superAdmin" */ './../Pages/SuperAdmin/Panel.vue')
const NewCustomer = () =>
    import( /* webpackChunkName: "superAdmin" */ './../Pages/SuperAdmin/NewCustomer.vue')
const AllCustomers = () =>
    import( /* webpackChunkName: "superAdmin" */ './../Pages/SuperAdmin/AllCustomers.vue')
// const BlackList = () =>
//     import( /* webpackChunkName: "superAdmin" */ './../Pages/SuperAdmin/BlackListDevices.vue')

// All related components for initiator
const Landing = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Landing.vue')
const Waiting = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Waiting.vue')
const Incompatible = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Incompatible.vue')
const Instructions = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Instructions.vue')
const Prerequisites = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Prerequisites.vue')
const InitiateQueue = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/InitiateQueue.vue')
const StartVideo = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/StartVideo.vue')
const Reschedule = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Reschedule.vue')
const Thankyou = () =>
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/Thankyou.vue')
const VpnDetected = () => 
    import( /* webpackChunkName: "initiator" */ './../Pages/EndUser/VpnDetected.vue')


//All related components for video-conference
const AdminFeedback = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Conference/AdminFeedback.vue');
const CallHistory = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Conference/CallHistory.vue');
const NewCall = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/ITAdmin/Conference/NewCall.vue');
const InitiateConference = () =>
    import( /* webpackChunkName: "vcipAdmin" */ './../Pages/EndUser/InitiateConference.vue');

const ThankyouCiti = () => import(/* webpackChunkName: "thankyouCiti" */ '../clientCustomPages/Thankyouciti.vue')
const CompletedCiti = () => import(/* webpackChunkName: "completedCiti" */ '../clientCustomPages/Completedciti.vue')
const UhOhCiti = () => import(/* webpackChunkName: "completedCiti" */ '../clientCustomPages/UhOhciti.vue')

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err) && Router.NavigationFailureType.duplicated) {
      // resolving navigation duplicated error
      return err;
    }
    // rethrowing error
    return Promise.reject(err);
  })
}

Vue.use(Router)

const routes = {
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH || '/',
    routes: [{
        path: '/',
        redirect: 'login',
    }, {
        path: '/login',
        name: 'login',
        component: Login
    },{
        path: '/adfs',
        name: 'adfs',
        component: Login
    },
    {
        path: '/updatepass',
        name: 'updatepass',
        component: PassUpdate,
        props: true
    },
    {
        path: '/updatepass/:userId/:token',
        name: 'updatepass-acl',
        component: PassUpdate
    },
    {
        path: '/login/auto/:jwt',
        name: 'REAutoLogin',
        component: Login
    }, {
        path: '/auto-login/:backopUserJwt',
        name: 'REAutoLogin',
        component: Login
    }, {
        path: '/admin',
        redirect: process.env.VUE_APP_ADMIN_REDIRECT_PATH || '/admin/login',
    }, {
        path: process.env.VUE_APP_ADMIN_LOGIN_PATH || '/admin/login',
        name: 'adminLogin',
        component: Login
    }, {
        path: '/admin/auto/:jwt',
        name: 'adminAutoLogin',
        component: Login
    }, {
        path: '/admin/auto/:uid/:token',
        name: 'adminAutoLoginToken',
        component: Login
    }, {
        path: '/super',
        redirect: "/super/login",
    }, {
        path: '/super/login',
        name: 'superLogin',
        component: Login
    }, {
        path: '/admin/control',
        name: 'controlPanel',
        component: ControlPanel,
        beforeEnter: (to, from, next) => {
            if(userDataStore.getters.userData && userDataStore.getters.userData.admin ? userDataStore.getters.userData.admin : false){
                next();
            }else{
                next('/admin/login');
            }
        },
        children: [{
            path: "/",
            redirect: "createUser"
        }, {
            path: "createUser", // <----- user is admin persona here
            name: "createUser",
            component: CreateUser
        }, {
            path: "createManager",
            name: "createManager",
            component: CreateManager
        }, {
            path: "create",
            name: "controlPanelLanding",
            component: CreateAgent
        }, {
            path: "users",
            component: ListUsers
        },
        {
            path: "mis-reports",
            component: MisReports
        },
        {
            path: "audit",
            component: Audit,
            children: [{
                path: "/",
                redirect: "pending"
            }, {
                path: "pending",
                component: PendingAudit
            }, {
                path: "completed",
                component: CompletedAudit
            }]
        },
        {
            path: "settings",
            component: Settings,
            children: [{
                path: "/",
                redirect: "basic"
            },
            {
                path: "basic/:id?",
                name: "settingsLanding",
                component: BasicSettings
            },
            {
                path: "advanced-settings/:id",
                component: AdvancedSettings
            }, {
                name: "conferenceSettings",
                path: "conference",
                component: ConferenceSettings
            }, {
                path: "skill-sets",
                component: SkillSets
            }, {
                path: "instruction-set/:id",
                name: "InstructionSet",
                component: InstructionSet
            }, {
                path: "instruction-set/mandatory/:id",
                name: "MandatoryPool",
                component: MandatoryPool
            },{
                path: "instruction-set/optional/:id",
                name: "OptionalPool",
                component: OptionalPool
            }, {
                path: "instruction-page/:id",
                component: InstructionPage
            }, {
                path: "admin-form/:id",
                component: AdminForm
            },
            {
                path: "uvkyc-basic/:id",
                component: UVKYCBasicSettings
            },
            {
                path: "uvkyc-instruction-set/:id",
                component: UVKYCInstructionSet
            },
            {
                path: "global-settings",
                component: AdminGlobalSettings
            }]
        }
        ]
    },
    {
        path: '/teams',
        name: "team",
        component: Teams,
        beforeEnter: (to, from, next) => {
            if(userDataStore.getters.userData && userDataStore.getters.userData.manager ? userDataStore.getters.userData.manager : false){
                next();
            }else{
                next('/login');
            }
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            if(userDataStore.getters.userData && userDataStore.getters.userData.user ? userDataStore.getters.userData.user : false){
                next();
            }else{
                next('/login');
            }
        },
        children: [{
            path: "/",
            name: "dashboardHistory",
            redirect: "history"
        }, {
            path: "history",
            component: VCIPCalls
        }, {
            path: "video",
            component: VCIPVideo
        }, {
            path: "video/:sessionId/:requestId",
            component: VCIPVideo
        }, {
            path: "conference",
            name: "conference",
            component: Conference,
            children: [{
                path: "/",
                name: "newConference",
                redirect: "new"
            },
            {
                path: "new",
                component: NewCall
            }, {
                path: "history",
                component: CallHistory
            }, {
                path: 'initiator',
                component: VCIPVideo
            }, {
                path: 'initiator/:sessionId/:requestId',
                name: 'adminCall',
                component: VCIPVideo
            }, {
                path: 'initiator/:session/feedback',
                name: 'adminFeedback',
                component: AdminFeedback
            }
            ]
        }]
    },
    {
        path: '/super/panel',
        name: 'superPanel',
        component: SuperPanel,
        beforeEnter: (to, from, next) => {
            if(userDataStore.getters.userData && userDataStore.getters.userData.superAdmin ? userDataStore.getters.userData.superAdmin : false){
                next();
            }else{
                next('/super/login');
            }
        },
        children: [{
            path: "/",
            redirect: "new"
        },
        {
            path: "new",
            component: NewCustomer
        },
        {
            path: "customer",
            component: AllCustomers
        },
        // {
        //     path: "blackList",
        //     component: BlackList
        // }
        ]
    },
    {
        path: '/initiate/:sessionId',
        component: InitiateConference,
        children: [
            {
                path: "/",
                redirect: "initiate"
            },
            {
                name: "initiateConf",
                path: "initiate",
                component: Prerequisites
            },
            {
                path: "video",
                name: "conferenceVideo",
                component: StartVideo
            }
        ]
    },
    {
        path: '/landing/:requestId',
        component: Landing,
        children: [
            {
                path: "/",
                redirect: "instructions"
            }, {
                path: "waiting",
                name: "waiting",
                component: Waiting
            }, {
                path: "incompatible",
                name: "incompatible",
                component: Incompatible
            }, {
                path: "instructions",
                name: "instructions",
                component: Instructions
            }, {
                path: "prerequisite",
                name: "prerequisite",
                component: Prerequisites
            }, {
                path: "initiate",
                name: "initiateQueue",
                component: InitiateQueue
            }, {
                path: "start",
                name: "startVideo",
                component: StartVideo
            }, {
                path: "reschedule/:incoming",
                name: "reschedule",
                component: Reschedule
            }
        ]
    },
    {
        path: "/vpndetected",
        name: "vpndetected",
        component: VpnDetected
    },
    {
        path: '/thankyou',
        name: 'thankyou',
        component: Thankyou
    },
    {
        path: "/error",
        name: "error",
        component: RejectPage
    },
    {
        path: "/unexpectedDrop/:reason",
        name: "unexpectedDrop",
        component: DropHandler
    },
    {
        path: "/error/:reason",
        name: "errorReason",
        component: RejectPage
    },
    {
        path: "/custom/citi/thankyou",
        name: "thankYouCiti",
        component: ThankyouCiti,
        meta: {
            title: "Thank You!"
        }
    },
    {
        path: "/custom/citi/completed",
        name: "thankYouCiti",
        component: CompletedCiti,
        meta: {
            title: "Your Video KYC has been completed!"
        }
    },
    {
        path: "/custom/citi/errorpage",
        name: "thankYouCiti",
        component: UhOhCiti,
        meta: {
            title: "Something went wrong!"
        }
    },
    {
        path: "*",
        redirect: "/error/pageNotFound",
        name: "errorPageNotFound",
        component: RejectPage
    }
    ]
}


const router = new Router(routes);

//// **** COPIED FROM https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
