<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="300">
      <v-card color="signzy_color" dark class="pa-2">
        <v-card-text>
          <span class="body-2">{{loaderText}}</span>
          <v-progress-linear indeterminate color="white" class="mt-1"></v-progress-linear>
          <!-- <v-row v-if="showCancelRejoin" class="mt-1">
            <v-btn @click="cancelRejoin()"
              id="cancelRejoin"
              class="cancel-screenshot mt-1">
              Cancel Rejoining
            </v-btn>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="screenshotLoader" persistent width="300">
      <v-card color="signzy_color" dark class="pa-2">
        <v-card-text>
          <span class="body-2">{{loaderText}}</span>
          <v-row class="mt-1" v-if="showProgressBar">
            <v-progress-linear v-model="uploadProgress" color="white" class="mt-1"></v-progress-linear>
            <v-col class="mb-1">
              <span>{{uploadProgress}} %</span>
            </v-col>
            <v-col class="speed-col mb-1">
              <span>{{uploadSpeed}} Mbps</span>
            </v-col>
          </v-row>
          <v-progress-linear v-else indeterminate color="white" class="mt-1"></v-progress-linear>
          <v-row v-if="showCancelScreenshotBtn" class="mt-1">
            <v-btn
              @click="clearScreenshotUpload(true)"
              class="cancel-screenshot mt-1"
            >
              Cancel Upload
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enablePersistedLoader" persistent width="300">
      <v-card color="signzy_color" dark class="pa-2">
        <v-card-text>
          <span class="body-2">{{persistedLoaderText}}</span>
          <v-progress-linear indeterminate color="white" class="mt-1"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    enablePersistedLoader: false,
    persistedLoaderText: "Please stand by",
    loaderText: "Please stand by",
    uploadProgress: 0,
    showProgressBar: false,
    showCancelScreenshotBtn: false,
    startTime: 0,
    endTime: 0,
    uploadSpeed: 0,
    screenshotLoader: false,
    showCancelRejoin: false
  }),
  methods: {
    showDialog: function () {
      this.dialog = true;
    },
    hideDialog: function () {
      this.dialog = false;
    },
    // cancelRejoin() {
    //   window.eventBus.$emit("cancelRejoin", true);
    //   this.showCancelRejoin=false;
    // },
    clearScreenshotUpload(cancelled = false) {
      this.uploadProgress = 0;
      this.uploadSpeed = 0;
      this.endTime = 0;
      this.startTime = 0;
      this.showCancelScreenshotBtn = false;
      if(cancelled) {
        eventBus.$emit("cancelScreenshotUploadRE");
        eventBus.$emit("vueLoader", false);
      }
    }
  },
  created() {
    window.eventBus.$on("vueLoaderTextChange", (text) => {
      this.loaderText = text;
    });
    // window.eventBus.$on("showCancelRejoin", (val) => {
    //   this.showCancelRejoin = val;
    // });
    window.eventBus.$on("vueLoader", (bool) => {
      this.dialog = bool;
      if(!bool){
        this.loaderText = "Please stand by"
      }
    });
    window.eventBus.$on("screenshotLoader", (bool) => {
      this.screenshotLoader = bool;
      if(!bool){
        this.loaderText = "Please stand by"
        this.clearScreenshotUpload();
      }
    });
    window.eventBus.$on("menuChange", () => {
      this.dialog = true;
    });
    window.eventBus.$on("persistedLoader", (bool, text = "") => {
      this.enablePersistedLoader = bool;
      if(!bool){
        this.persistedLoaderText = "Please stand by";
      } else {
        if(text){
          this.persistedLoaderText = text;
        }
      }
    });
    window.eventBus.$on("updateProgress", (progressEvent) => {
      this.endTime = (new Date()).getTime();
      this.showProgressBar = true;
      this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      var duration = (this.endTime - this.startTime) / 1000;
      var bitsLoaded = progressEvent.loaded * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      this.uploadSpeed = (speedBps / (1024 * 1024)).toFixed(2);
      this.startTime = this.endTime;

      if(this.uploadProgress == 100) {
        window.clearTimeout(this.cancelBtnTimeout);
        this.showProgressBar = false;
      }
    });
    window.eventBus.$on("startUploadTime", () => {
      this.startTime = (new Date()).getTime();
      console.log("START TIME: ", this.startTime);
      this.cancelBtnTimeout = setTimeout(() => {
        this.showCancelScreenshotBtn = true;
      }, 10000);
    })
  },
  destroyed() {
    this.loaderText = "Please stand by"
  }
};
</script>
<style scoped>
.speed-col {
  text-align: right;
}
.cancel-screenshot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.cancel-screenshot:hover {
  background-color: #DD5865;
  color: white
}

#cancelRejoin {
  background-color: black;
}

#cancelRejoin:hover {
  background-color: red;
}
</style>