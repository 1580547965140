import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/Plugins/Vuetify'
import router from '@/Plugins/Router'
import { eventBus } from '@/Plugins/eventbus'
import axios from 'axios'
import { userDataStore } from "@/Plugins/UserData.Store";
import 'viewerjs/dist/viewer.css';
import CryptoJS from 'crypto-js'
import forge from 'node-forge'

(async function () {

    window.addEventListener("beforeunload", function (event) {
      sessionStorage.removeItem("__lock");
    });

    if (sessionStorage.getItem("__lock")) {
      sessionStorage.clear();
      console.warn("Found a lock in session storage. The storage was cleared.");
    }

    sessionStorage.setItem("__lock", "1");

    const encryptionKey = CryptoJS.lib.WordArray.random(32).toString();
    userDataStore.commit("setEncKey", encryptionKey);

    const publicPem = userDataStore.getters.getPublicKey;
    const publicKey = forge.pki.publicKeyFromPem(publicPem);
    const encryptedString = publicKey.encrypt(encryptionKey);
    const encryptedEncryptionKey = btoa(encryptedString);
    
    await axios
      .get(`/scheduling/customers/env`, {'headers': { 'x-encrypted': encryptedEncryptionKey }})
      .then((encryptedText) => {
        const decryptedData = CryptoJS.AES.decrypt(encryptedText.data, encryptionKey).toString(CryptoJS.enc.Utf8);

        userDataStore.commit("setEnvValues", JSON.parse(decryptedData));
      })
      .catch((error) => {
        console.error("Failed to fetch config value:", error);
      });
})();

import appGlobals from "@/config.js"
import {Howl, Howler} from 'howler'
import { encrypt , decrypt } from './flux/encdec.flux'
import VueTour from 'vue-tour'

// window.$ = require('@/assets/js/jquery.js');
// window.JitsiMeetJS = require('@/assets/js/jquery.js');
import { SignzyStorageService } from '@/Plugins/SignzyStorageService';

window.$ = require('jquery')
window.JQuery = require('jquery')
//window.JitsiMeetJS = require('./assets/js/jitsi');
window.JitsiMeetJS = require('./assets/js/lib-jitsi-meet.min');
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

Vue.config.productionTip = false
// Declaring global audio object, can be used to render audio from any component
Vue.prototype.$notificationSound = new Howl({
  src: require('@/assets/audio1.wav'),
  loop: true,
  volume: 1,
  onplayerror: function() {
    audioFile.once('unlock', function() {
        console.log("User Interation done for notification");
    });
  }
});

let store = new SignzyStorageService("session");

const axiosInstance = axios.create({
  timeout: 180000,
  baseURL: appGlobals.base_url
});
axiosInstance.interceptors.request.use(function (config) {


  let dummyData ;
  if(config.data){
    dummyData = config.data;
  }else if(config.body){
    dummyData = config.body;
    config.data = config.body;
    delete config.body;
  }

  /**
   * fix:
   * when delete request is made via URL only and there's no payload
   * in that scenario dummyData is undefined and no encryption is needed.
   **/
  if (config.method == 'delete' && !dummyData) return config;

  /// need to add isencryptedd key to dummydata
  if (config.method == 'get' || config.data instanceof FormData) {
    return config;
  }
  dummyData['isEncrypted'] = true;
  config.data = {};
  config.data["data"] = encrypt(
    JSON.stringify(dummyData),
    userDataStore.getters.envValues.otpEncKey
  );
  config.headers[config.method].isEncrypted = true;
  return config;
}, function (error) {

  eventBus.$emit('vueLoader', false);
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(async function (response) {
  let resultobjString ;
  // for decryption we will not check boolean flag encryption_enabled
  // if(appGlobals.encryption_enabled){
    // if( response.headers.isencrypted == "true" ){
  if (response?.data?.data) {
    try {
     // Wait for otpEncKey to be available
     let otpEncKey = await getOtpEncKey();
    resultobjString = decrypt(
      response.data.data,
      otpEncKey
    );
    response.data = JSON.parse(resultobjString)
    if (typeof response.data === 'string') {
      try {
        response.data = JSON.parse(response.data);
      } catch (error) {
        console.error("Invalid JSON string:", error);
      }
    }
  }
  catch (error) {
      console.error("Error during decryption:", error);
    }
  }
  // }


  eventBus.$emit('vueLoader', false);
  return response;
}, function (error) {
  eventBus.$emit('vueLoader', false);
  if (error.response && error.response.status == 401) {
    store.flush();
  } else if (error.response && error.response.status == 409) {
    eventBus.$emit("vueSnack", "Multiple Sessions not allowed!");
  }
  return Promise.reject(error);
});
// Helper function to wait for otpEncKey availability
function getOtpEncKey() {
  return new Promise((resolve, reject) => {
    const checkKey = () => {
      let otpEncKey = userDataStore.getters.envValues.otpEncKey;
      if (otpEncKey) {
        resolve(otpEncKey);
      } else {
        setTimeout(checkKey, 300); // Check again after 300ms if otpEncKey is not available
      }
    };
    checkKey();
  });
}

let snackBar = (msg) => {
  eventBus.$emit("vueSnack", msg);
}

window.snackBar = snackBar;
window.store = store;
window.appGlobals = appGlobals;
window.axiosInstance = axiosInstance;
window.eventBus = eventBus;

let vue = new Vue({
  vuetify,
  router,
  store: userDataStore,
  render: h => h(App),
}).$mount('#app')

try {
  axiosInstance.defaults.headers.common['Authorization'] = vue.$store.getters.userData.token;
} catch (err) {
  axiosInstance.defaults.headers.common['Authorization'] = "";
}
