import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    theme: {
        themes: {
            light: {
                signzy_color: '#313545',
                signzy_color_dark: '#242835',
                signzy_blue: '#1365c0',
                app_bar_icon: '#0184c4',
                app_bar_color: '#1365c0',
                signzy_header_color: '#FFFFFF',
            },
        },
        dark: false
    },
}

export default new Vuetify(opts)