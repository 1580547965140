const CryptoJS = require('crypto-js')
let encrypt = (rawText, key) => {
    try{
        let ciphertext = CryptoJS.AES.encrypt(rawText, key);
        return ciphertext.toString();
    
    }catch(error){
       console.log('Payload Encryption failed: ',error);
       return '';
    }
}

let decrypt = (ciphertext, key) => {
    try {
        let decryptText = CryptoJS.AES.decrypt(ciphertext, key).toString(CryptoJS.enc.Utf8);
        //Deepak: Why this text conversion two times ?
        return decryptText.toString(CryptoJS.enc.Utf8);
    }
    catch(error){
        console.log('Payload Decryption failed: ',error);
        return '';
    }    
}

module.exports = {
    encrypt,
    decrypt
}