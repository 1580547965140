<template>
  <v-card>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >{{ text }}</v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      y: "top",
      x: "",
      mode: "",
      timeout: 3000,
      text: "Hello, I'm a snackbar"
    };
  },
  created() {
    window.eventBus.$on("vueSnack", text => {
      this.text = text;
      this.snackbar = true;
    });
  }
};
</script>